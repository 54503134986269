<template>
	<div class="-empty">
		<div class="-wrap">
			<i>404 NOT FOUND</i>
			<i class="-mx">|</i>
			<i>逐步上线中</i>
		</div>
	</div>
</template>

<script>
</script>

<style>
.-empty { display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; color: #545454; background: rgba(247,250,252,1);}
.-empty .-wrap { font-size: 16px; color: rgba(160,174,192,1); padding-bottom: 30px;}
</style>
